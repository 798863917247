html,
body,
#root {
  .leaflet-container {
    font-family: inherit;
    z-index: 1;
  }
  .leaflet-container .leaflet-control-attribution {
    display: none;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 0;
    overflow: auto;
  }

  ::-webkit-scrollbar-track-piece {
    width: 3px;
    background: white;
  }

  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 5px;
  }
}

.scrollbox {
  overflow: auto;
  max-height: 200px;
  margin: 50px auto;

  p {
    display: table-cell;
    padding: 2em;
  }
  background-image: linear-gradient(to left, #f0f0f0, #f0f0f0),
    linear-gradient(to right, #f0f0f0, #f0f0f0),
    linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));

  background-position:
    left center,
    right center,
    left center,
    right center;
  background-repeat: no-repeat;
  background-color: #f0f0f0;
  background-size:
    20px 100%,
    20px 100%,
    10px 100%,
    10px 100%;

  background-attachment: local, local, scroll, scroll;
}
